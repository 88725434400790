<template>
  <header class="my-3 flex justify-center self-center">
    <svg
      class="mt-2"
      width="1rem"
      height="2rem"
      viewBox="0 0 15.84 60.63"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0,0H15.84V5.76H6.26V54.87h9.58v5.76H0Z" fill="#2b2a29" />
    </svg>
    <nuxt-link to="/">
      <h1 class="text-3xl, font-bold">{{ title }}</h1>
    </nuxt-link>
    <svg
      class="mt-2"
      width="1rem"
      height="2rem"
      viewBox="0 0 15.84 60.63"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0,60.63V54.87H9.58V5.76H0V0H15.84V60.63Z" fill="#2b2a29" />
    </svg>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
  name: "Logo",
  props: {
    title: {
      type: String as PropType<string>,
      default: "Aventuria Database",
    },
  },
});
</script>

<style scoped>
header > a {
  text-decoration: none;
}

header > a:visited {
  color: unset;
}
</style>
